import React, { Component } from 'react';
import ReactTestUtils from 'react-dom/test-utils';
import PropTypes, { instanceOf } from 'prop-types';
// import 'antd/dist/antd.min.css';
import './../antd.css';
import CreateForm from '../forms/CreateForm';
import ProfilesActions from './../includes/ProfilesActions';
import './../menuPauPrincipal/css/MenuPauPrincipal.css';
import update from 'immutability-helper';
import { eventClickPauActions } from "../menuPauPrincipal/js/MenuPauPrincipal";
import { pictosPanel } from "../constants/pictosPanel";
import AccesibilityActions from './AccesibilityActions';
import { Cookies, withCookies } from 'react-cookie';
import { reactTranslate } from "../constants/reactTranslate";
import { InputColor } from "../Components/InputColor";

class CreateSection extends Component {

  static propTypes = {
    cookies: instanceOf( Cookies ).isRequired
  };

  constructor( props ) {

    super( props );

    const {
            settings,
            shows
          } = props;

    this.state = {
      settings,
      shows,
      sections: null
    };

  }

  /*componentDidUpdate( prevProps, prevState ) {

    if ( ! compare( prevProps.settings, this.props.settings ) ) {
      this.setSections( this.props.settings );
    }

  }*/

  sectionPerfiles( settings, shows, pictos, t ) {

    return {
      header   : t.profiles.init,
      id       : "pauMenuPerfiles",
      icon     : pictos.perfiles.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          group: [
            {
              title   : t.profiles.default,
              id      : "pauPerfilPorDefecto",
              key     : "default",
              icon    : pictos.perfiles.porDefecto,
              classes : "pauNot",
              value   : settings.perfil_defecto.value || false,
              show    : shows.perfil_defecto.show || false,
              description : 'texto estatico por el momento 1',
              callback: ( checked ) => {

                let valCheck    = "default",
                    { cookies } = this.props,
                    new_value   = {},
                    setti       = cookies.get( "settings" );

                if ( checked ) {

                  cookies.remove( "profileDefault", { path: "/" } );
                  cookies.set( "profileDefault", valCheck, { path: "/" } );

                  switch ( setti.perfil_defecto.value ) {

                    case "visual-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivVisual" ) );
                      break;

                    case "auditive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivAudi" ) );
                      break;

                    case "cognitive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivCogni" ) );
                      break;

                    case "custom" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPer" ) );
                      break;

                    default:
                      break;

                  }

                  valCheck = "default";

                  ProfilesActions.selection( valCheck );

                  AccesibilityActions.resetCoockieFocus( cookies );

                  new_value[ "perfil_defecto" ] = {
                    value: { $set: valCheck }
                  };

                  setti = cookies.get( "settings" );

                  cookies.remove( "settings", { path: "/" } );
                  cookies.set( "settings", update( setti, new_value ), { path: "/" } );

                  this.setState( update( this.state, {
                    settings: new_value
                  } ) );

                } else {

                  new_value[ "perfil_defecto" ] = {
                    value: { $set: '' }
                  };

                  setti = cookies.get( "settings" );

                  cookies.remove( "settings", { path: "/" } );
                  cookies.set( "settings", update( setti, new_value ), { path: "/" } );

                  this.setState( update( this.state, {
                    settings: new_value
                  } ) );

                }

              }
            },
            {
              title       : t.profiles.visualDiversity,
              id          : "pauPerfilDivVisual",
              key         : "visual-diversity",
              icon        : pictos.perfiles.visual,
              classes     : "pauNot",
              value       : settings.perfil_defecto.value || false,
              show        : shows.perfil_defecto.show || false,
              callback: ( checked ) => {

                let valCheck    = "visual-diversity",
                    { cookies } = this.props,
                    new_value   = {},
                    setti       = cookies.get( "settings" );

                if ( checked ) {

                  cookies.remove( "profileDefault", { path: "/" } );
                  cookies.set( "profileDefault", valCheck, { path: "/" } );

                  switch ( setti.perfil_defecto.value ) {

                    case "default" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                      break;

                    case "auditive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivAudi" ) );
                      break;

                    case "cognitive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivCogni" ) );
                      break;

                    case "custom" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPer" ) );
                      break;

                    default:
                      break;

                  }

                  // valCheck = "visual-diversity";

                  ProfilesActions.selection( valCheck );

                  new_value[ "perfil_defecto" ] = {
                    value: { $set: valCheck }
                  };

                  setti = cookies.get( "settings" );

                  cookies.remove( "settings", { path: "/" } );
                  cookies.set( "settings", update( setti, new_value ), { path: "/" } );

                  this.setState( update( this.state, {
                    settings: new_value
                  } ) );

                } else {

                  let profileDefault = cookies.get( "profileDefault" );

                  if ( profileDefault === valCheck ) {
                    ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                  }

                }

              }

            },
            {
              title       : t.profiles.auditiveDiversity,
              id          : "pauPerfilDivAudi",
              key         : "auditive-diversity",
              icon        : pictos.perfiles.auditivo,
              classes     : "pauNot",
              value       : settings.perfil_defecto.value || false,
              show        : shows.perfil_defecto.show || false,
              callback: ( checked ) => {

                let valCheck    = "auditive-diversity",
                    { cookies } = this.props,
                    new_value   = {},
                    setti       = cookies.get( "settings" );

                if ( checked ) {

                  cookies.remove( "profileDefault", { path: "/" } );
                  cookies.set( "profileDefault", valCheck, { path: "/" } );

                  switch ( setti.perfil_defecto.value ) {

                    case "default" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                      break;

                    case "visual-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivVisual" ) );
                      break;

                    case "cognitive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivCogni" ) );
                      break;

                    case "custom" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPer" ) );
                      break;

                    default:
                      break;

                  }

                  // valCheck = "auditive-diversity";

                  ProfilesActions.selection( valCheck );

                  new_value[ "perfil_defecto" ] = {
                    value: { $set: valCheck }
                  };

                  setti = cookies.get( "settings" );

                  cookies.remove( "settings", { path: "/" } );
                  cookies.set( "settings", update( setti, new_value ), { path: "/" } );

                  this.setState( update( this.state, {
                    settings: new_value
                  } ) );

                } else {

                  let profileDefault = cookies.get( "profileDefault" );

                  if ( profileDefault === valCheck ) {
                    ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                  }

                }

              }
            },
            {
              title       : t.profiles.cognitiveDiversity,
              id          : "pauPerfilDivCogni",
              key         : "congnitive-diversity",
              icon        : pictos.perfiles.cognitiva,
              classes     : "pauNot",
              value       : settings.perfil_defecto.value || false,
              show        : shows.perfil_defecto.show || false,
              callback: ( checked ) => {

                let valCheck    = "cognitive-diversity",
                    { cookies } = this.props,
                    new_value   = {},
                    setti       = cookies.get( "settings" );

                if ( checked ) {

                  cookies.remove( "profileDefault", { path: "/" } );
                  cookies.set( "profileDefault", valCheck, { path: "/" } );

                  switch ( setti.perfil_defecto.value ) {

                    case "default" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                      break;

                    case "visual-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivVisual" ) );
                      break;

                    case "auditive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivAudi" ) );
                      break;

                    case "custom" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPer" ) );
                      break;

                    default:
                      break;

                  }

                  // valCheck = "cognitive-diversity";

                  // if( setti.perfil_defecto.value !== valCheck ) {

                  ProfilesActions.selection( valCheck );

                  new_value[ "perfil_defecto" ] = {
                    value: { $set: valCheck }
                  };

                  setti = cookies.get( "settings" );

                  cookies.remove( "settings", { path: "/" } );
                  cookies.set( "settings", update( setti, new_value ), { path: "/" } );

                  this.setState( update( this.state, {
                    settings: new_value
                  } ) );

                  // }

                } else {

                  let profileDefault = cookies.get( "profileDefault" );

                  if ( profileDefault === valCheck ) {
                    ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                  }

                }

              }
            },
            {
              title       : t.profiles.custom,
              id          : "pauPerfilPer",
              key         : "custom",
              icon        : pictos.perfiles.personalizado,
              classes     : "pauNot",
              value       : settings.perfil_defecto.value || false,
              show        : shows.perfil_defecto.show || false,
              callback: ( checked ) => {

                let valCheck    = "custom",
                    { cookies } = this.props,
                    new_value   = {},
                    setti       = cookies.get( "settings" );

                if ( checked ) {

                  cookies.remove( "profileDefault", { path: "/" } );
                  cookies.set( "profileDefault", valCheck, { path: "/" } );

                  switch ( setti.perfil_defecto.value ) {

                    case "default" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                      break;

                    case "visual-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivVisual" ) );
                      break;

                    case "auditive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivAudi" ) );
                      break;

                    case "cognitive-diversity" :
                      ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilDivCogni" ) );
                      break;

                    default:
                      break;

                  }

                  // valCheck = "custom";

                  ProfilesActions.selection( valCheck );

                  new_value[ "perfil_defecto" ] = {
                    value: { $set: valCheck }
                  };

                  setti = cookies.get( "settings" );

                  cookies.remove( "settings", { path: "/" } );
                  cookies.set( "settings", update( setti, new_value ), { path: "/" } );

                  this.setState( update( this.state, {
                    settings: new_value
                  } ) );

                } else {

                  let profileDefault = cookies.get( "profileDefault" );

                  if ( profileDefault === valCheck ) {
                    ReactTestUtils.Simulate.click( document.querySelector( "#pauPerfilPorDefecto" ) );
                  }

                }

              }

            }
          ]
        }
      ]
    };

  }

  sectionGeneral( settings, shows, pictos, t ) {
    return {
      header   : t.general.init,
      id       : "pauMenuGeneral",
      icon     : pictos.general.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          title       : t.general.showHideAll,
          id          : "pau_react_geral_todo",
          classes     : "pauNot",
          icon        : pictos.general.todo,
          value       : settings.geral_todo.value,
          show        : shows.geral_todo.show,
          type        : "switch",
          callback: ( checked ) => {

            let { cookies }   = this.props,
                setti         = cookies.get( "settings" ),
                font_size_val = 1;

            if ( checked ) {

              if ( !setti.geral_video.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_video" ) );
              }

              if ( !setti.geral_sonido.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_sonido" ) );
              }

              if ( !setti.geral_lectura.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_lectura" ) );
              }

              if ( setti.geral_video.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_video" ) );
              }

              if ( setti.geral_sonido.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_sonido" ) );
              }

              if ( setti.geral_lectura.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_lectura" ) );
              }

              /**
               * Se agrega script para ocultar todo
               */

              if ( !setti.nav_bigcursor.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_bigcursor" ) );
              }

              if ( !setti.nav_clic.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_clic" ) );
              }

              if ( !setti.nav_donde.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_donde" ) );
              }

              // if( ! setti.nav_zoom.value ) {
              //     ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_zoom" ) );
              // }

              if ( setti.font_size.value !== 1 ) {

                let $selcFontSize = document.querySelector( "#pau_react_font_size" );

                $selcFontSize.value = 1;

                ReactTestUtils.Simulate.change( $selcFontSize );
                ReactTestUtils.Simulate.focus( $selcFontSize );

              }

              // if( setti.font_dislexia_legible.value !== "readable" ) {
              //     ReactTestUtils.Simulate.click( document.querySelector( "#pauFuentesLegible" ) );
              // }

              if ( !setti.font_resaltar_links.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_resaltar_links" ) );
              }

              if ( !setti.visual_resaltar_focus.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_resaltar_focus" ) );
              }

            } else {

              if ( setti.geral_video.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_video" ) );
              }

              if ( setti.geral_sonido.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_sonido" ) );
              }

              if ( setti.geral_lectura.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_geral_lectura" ) );
              }

              /**
               * Se agrega script para ocultar todo
               */

              if ( setti.nav_bigcursor.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_bigcursor" ) );
              }

              if ( setti.nav_clic.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_clic" ) );
              }

              if ( setti.nav_donde.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_donde" ) );
              }

              if ( setti.nav_zoom.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_nav_zoom" ) );
              }

              if ( setti.font_dislexia_legible.value === "readable" ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pauFuentesLegible" ) );
              }

              if ( setti.font_dislexia_legible.value === "dyslexia" ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pauFuentesDislexia" ) );
              }

              if ( setti.font_resaltar_links.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_resaltar_links" ) );
              }

              if ( setti.visual_colors.value === "blackwhite" ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_blanco_negro" ) );
              }

              if ( setti.visual_colors.value === "invert-colors" ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_invertir_colores" ) );
              }

              if ( setti.visual_resaltar_focus.value ) {
                ReactTestUtils.Simulate.click( document.querySelector( "#pau_react_resaltar_focus" ) );
              }

              if ( setti.font_size.value !== 1 ) {

                let $selcFontSize = document.querySelector( "#pau_react_font_size" );

                $selcFontSize.value = 1;

                ReactTestUtils.Simulate.change( $selcFontSize );
                ReactTestUtils.Simulate.focus( $selcFontSize );

              }

              font_size_val = 1;

            }

            let geral_todo            = {
                  value: { $set: checked }
                },
                geral_video           = {
                  value: { $set: checked }
                },
                geral_sonido          = {
                  value: { $set: checked }
                },
                geral_lectura         = {
                  value: { $set: checked }
                },
                nav_bigcursor         = {
                  value: { $set: checked }
                },
                nav_clic              = {
                  value: { $set: checked }
                },
                nav_donde             = {
                  value: { $set: checked }
                },
                nav_zoom              = {
                  value: { $set: checked }
                },
                font_size             = {
                  value: { $set: font_size_val }
                },
                font_dislexia_legible = {
                  value: { $set: checked }
                },
                font_resaltar_links   = {
                  value: { $set: checked }
                },
                visual_colors         = {
                  value: { $set: checked }
                },
                visual_resaltar_focus = {
                  value: { $set: checked }
                };

            // AccesibilityActions.tamanioFuente( value );
            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              geral_todo,
              geral_video,
              geral_sonido,
              geral_lectura,
              nav_bigcursor,
              nav_clic,
              nav_donde,
              nav_zoom,
              font_dislexia_legible,
              font_size,
              font_resaltar_links,
              visual_colors,
              visual_resaltar_focus
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                geral_todo,
                geral_video,
                geral_sonido,
                geral_lectura,
                nav_bigcursor,
                nav_clic,
                nav_donde,
                nav_zoom,
                font_dislexia_legible,
                font_size,
                font_resaltar_links,
                visual_colors,
                visual_resaltar_focus
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        {
          title       : t.general.activateGeralSound,
          id          : "pau_react_geral_sonido",
          classes     : "pauNot",
          icon        : pictos.general.sonido,
          value       : settings.geral_sonido.value,
          show        : shows.geral_sonido.show,
          type        : "switch",
          callback: ( checked ) => {

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let geral_sonido = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              geral_sonido
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                geral_sonido
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        {
          title       : t.general.allLSEVideos,
          id          : "pau_react_geral_video",
          classes     : "pauNot",
          icon        : pictos.general.video,
          value       : settings.geral_video.value,
          show        : shows.geral_video.show,
          type        : "switch",
          callback: ( checked ) => {

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let geral_video = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              geral_video
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                geral_video
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        {
          title       : t.general.readingSoundContent,
          id          : "pau_react_geral_lectura",
          classes     : "pauNot",
          icon        : pictos.general.lectura,
          value       : settings.geral_lectura.value,
          show        : shows.geral_lectura.show,
          type        : "switch",
          callback: ( checked ) => {

            let $pauReactLocucionLSE = document.querySelector( '#pauReactLocucionLSE' )/*,
             $pauReactPageAudio   = document.querySelector( '#pauReactPageAudio' ),
             $pauReactPageVideo   = document.querySelector( '#pauReactPageVideo' )*/;

            if ( checked ) {
              $pauReactLocucionLSE.show();
            } else {
              $pauReactLocucionLSE.hide();
            }

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let geral_lectura = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              geral_lectura
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                geral_lectura
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        }
      ]
    }
  }

  sectionNavigation( settings, shows, pictos, t ) {
    return {
      header   : t.navigation.init,
      id       : "pauMenuNavegacion",
      icon     : pictos.navegacion.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          title   : t.navigation.largeCursor,
          id      : "pau_react_nav_bigcursor",
          classes : "pauNot",
          icon    : pictos.navegacion.bigcursor,
          value   : settings.nav_bigcursor.value,
          show    : shows.nav_bigcursor.show,
          type    : "switch",
          callback: ( checked ) => {

            AccesibilityActions.bigcursor( checked );

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let nav_bigcursor = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              nav_bigcursor
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                nav_bigcursor
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        {
          title   : t.navigation.clickSound,
          id      : "pau_react_nav_clic",
          classes : "pauNot",
          icon    : pictos.navegacion.nav,
          value   : settings.nav_clic.value,
          show    : shows.nav_clic.show,
          type    : "switch",
          callback: ( checked ) => {

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let nav_clic = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              nav_clic
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                nav_clic
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        /*{
          title   : t.navigation.showWhere,
          id      : "pau_react_nav_donde",
          classes : "pauNot",
          icon    : pictos.navegacion.donde,
          value   : settings.nav_donde.value,
          show    : shows.nav_donde.show,
          type    : "switch",
          callback: ( checked ) => {

            AccesibilityActions.donde( checked );

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let nav_donde = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              nav_donde
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                nav_donde
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },*/
        {
          title       : t.navigation.zoomLinks,
          id          : "pau_react_nav_zoom",
          classes     : "pauNot",
          icon        : pictos.navegacion.zoom,
          value       : settings.nav_zoom.value,
          show        : shows.nav_zoom.show,
          type        : "switch",
          callback: ( checked ) => {

            AccesibilityActions.zoomLink( checked );

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let nav_zoom = {
              value: { $set: checked }
            };

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              nav_zoom
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                nav_zoom
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
      ]
    };
  }

  sectionFuentes( settings, shows, pictos, t ) {
    return {
      header   : t.fonts.init,
      id       : "pauMenuFuentes",
      icon     : pictos.fuentes.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          title   : t.fonts.fontSize,
          id      : "pau_react_font_size",
          classes : "pauNot",
          icon    : pictos.fuentes.tamanio,
          value   : settings.font_size.value,
          show    : shows.font_size.show,
          type    : "number",
          min     : 1,
          max     : 9,

          callback: ( value ) => {

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let font_size = {
              value: { $set: value }
            };

            AccesibilityActions.tamanioFuente( value );

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              font_size
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                font_size: font_size
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        {
          group: [
            {
              title   : t.fonts.readable,
              id      : "pauFuentesLegible",
              key     : "readable",
              classes : "pauNot",
              icon    : pictos.fuentes.legible,
              value   : settings.font_dislexia_legible.value || false,
              show    : shows.font_legible.show,
              type    : "switch",
              callback: ( checked ) => {

                let valCheck    = "",
                    { cookies } = this.props,
                    setti       = cookies.get( "settings" );

                AccesibilityActions.fuentes( "readable", checked );

                if ( checked ) {

                  if ( setti.font_dislexia_legible.value === "dyslexia" ) {
                    ReactTestUtils.Simulate.click( document.getElementById( "pauFuentesDislexia" ) );
                  }

                  valCheck = "readable";

                }

                let font_dislexia_legible = {
                  value: { $set: valCheck }
                };

                cookies.remove( "settings", { path: "/" } );
                cookies.set( "settings", update( setti, {
                  font_dislexia_legible
                } ), { path: "/" } );

                this.setState( update( this.state, {
                  settings: {
                    font_dislexia_legible
                  }
                } ) );

                let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

                if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

                  let $profileCustom = document.getElementById( "pauPerfilPer" );

                  ReactTestUtils.Simulate.click( $profileCustom );

                }

              }
            },
            {
              title   : t.fonts.dyslexia,
              id      : "pauFuentesDislexia",
              key     : "dyslexia",
              classes : "pauNot",
              icon    : pictos.fuentes.dislexia,
              value   : settings.font_dislexia_legible.value || false,
              show    : shows.font_dislexia.show,
              type    : "switch",
              callback: ( checked ) => {

                let valCheck    = "",
                    { cookies } = this.props,
                    setti       = cookies.get( "settings" );

                AccesibilityActions.fuentes( "dyslexia", checked );

                if ( checked ) {

                  if ( setti.font_dislexia_legible.value === "readable" ) {
                    ReactTestUtils.Simulate.click( document.getElementById( "pauFuentesLegible" ) );
                  }

                  valCheck = "dyslexia";

                }

                let font_dislexia_legible = {
                  value: { $set: valCheck }
                };

                cookies.remove( "settings", { path: "/" } );
                cookies.set( "settings", update( setti, {
                  font_dislexia_legible
                } ), { path: "/" } );

                this.setState( update( this.state, {
                  settings: {
                    font_dislexia_legible
                  }
                } ) );

                let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

                if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

                  let $profileCustom = document.getElementById( "pauPerfilPer" );

                  ReactTestUtils.Simulate.click( $profileCustom );

                }

              }
            }
          ]
        },
        {
          title   : t.fonts.highlightLinks,
          id      : "pau_react_resaltar_links",
          classes : "pauNot",
          icon    : pictos.fuentes.enlaces,
          value   : settings.font_resaltar_links.value,
          show    : shows.font_resaltar_links.show,
          type    : "switch",
          callback: ( checked ) => {

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let font_resaltar_links = {
              value: { $set: checked }
            };

            AccesibilityActions.resaltarEnlaces( checked );

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              font_resaltar_links
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                font_resaltar_links
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        }
      ]
    };
  }

  sectionVisual( settings, shows, pictos, t ) {

    const { cookies } = this.props;
    const focusStyle = cookies.get( "focusStyle" );

    const callbackChangeColorFocus = ( type ) => {

      return ( color ) => {

        let id = `pau-focus-style-${type}`,
            prop;

        document.querySelector( "#" + id )?.remove();

        const focusStyle = cookies.get( "focusStyle" );
        cookies.remove( "focusStyle", { path: "/" } );
        cookies.set( "focusStyle", update( focusStyle, {
          ['border' + type.capitalize()]: { $set: color }
        } ), { path: "/" } );

        switch ( type ) {

          case "inner":
            prop = `outline: 5px solid ${color}`;
            break;

          case "outer":
            prop = `box-shadow: 0 0 0 8px ${color}`;
            break;

        }

        let style = `<style id="${id}">
                :focus {
                  ${prop} !important;
                }
            </style>`;

        document.head.insertAdjacentHTML( 'beforeend', style );

      }

    }

    return {
      header   : t.visual.init,
      id       : "pauMenuVisual",
      icon     : pictos.visual.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          group: [
            {
              title   : t.visual.blackWhite,
              id      : "pau_react_blanco_negro",
              key     : "blackwhite",
              classes : "pauNot",
              icon    : pictos.visual.blancoNegro,
              value   : settings.visual_colors.value,
              show    : shows.visual_colors_bn.show,

              callback: ( checked ) => {

                let valCheck    = "",
                    { cookies } = this.props,
                    setti       = cookies.get( "settings" );

                AccesibilityActions.visual( "blackwhite", checked );

                if ( checked ) {

                  if ( setti.visual_colors.value === "invert-colors" ) {
                    ReactTestUtils.Simulate.click( document.getElementById( "pau_react_invertir_colores" ) );
                  }

                  valCheck = "blackwhite";

                }

                let visual_colors = {
                  value: { $set: valCheck }
                };

                cookies.remove( "settings", { path: "/" } );
                cookies.set( "settings", update( setti, {
                  visual_colors
                } ), { path: "/" } );

                this.setState( update( this.state, {
                  settings: {
                    visual_colors
                  }
                } ) );

                let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

                if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

                  let $profileCustom = document.getElementById( "pauPerfilPer" );

                  ReactTestUtils.Simulate.click( $profileCustom );

                }

              }
            },
            {
              title   : t.visual.invertColors,
              id      : "pau_react_invertir_colores",
              key     : "invert-colors",
              classes : "pauNot",
              icon    : pictos.visual.invertirColores,
              value   : settings.visual_colors.value,
              show    : shows.visual_colors_invert.show,
              callback: ( checked ) => {

                let valCheck    = "",
                    { cookies } = this.props,
                    setti       = cookies.get( "settings" );

                AccesibilityActions.visual( "invert-colors", checked );

                if ( checked ) {

                  if ( setti.visual_colors.value === "blackwhite" ) {
                    ReactTestUtils.Simulate.click( document.getElementById( "pau_react_blanco_negro" ) );
                  }

                  valCheck = "invert-colors";

                }

                let visual_colors = {
                  value: { $set: valCheck }
                };

                cookies.remove( "settings", { path: "/" } );
                cookies.set( "settings", update( setti, {
                  visual_colors
                } ), { path: "/" } );

                this.setState( update( this.state, {
                  settings: {
                    visual_colors
                  }
                } ) );

                let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

                if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

                  let $profileCustom = document.getElementById( "pauPerfilPer" );

                  ReactTestUtils.Simulate.click( $profileCustom );

                }

              }
            }
          ]
        },
        {
          title     : t.visual.selectionFocus,
          id        : "pau-react-resaltar-focus",
          classes   : "pauNot",
          icon      : pictos.fuentes.enlaces,
          value     : settings.visual_resaltar_focus.value,
          show      : shows.visual_resaltar_focus.show,
          type      : "switch",
          callback: ( checked ) => {

            let { cookies } = this.props,
                setti       = cookies.get( "settings" );

            let visual_resaltar_focus = {
              value: { $set: checked }
            };

            AccesibilityActions.focus( checked );

            cookies.remove( "settings", { path: "/" } );
            cookies.set( "settings", update( setti, {
              visual_resaltar_focus
            } ), { path: "/" } );

            this.setState( update( this.state, {
              settings: {
                visual_resaltar_focus
              }
            } ) );

            let noPersonalizado = parseInt( cookies.get( "noPersonalizado" ), 10 );

            if ( !noPersonalizado && setti.perfil_defecto.value !== "custom" ) {

              let $profileCustom = document.getElementById( "pauPerfilPer" );

              ReactTestUtils.Simulate.click( $profileCustom );

            }

          }
        },
        {
          title   : t.visual.customStyleFocus,
          id      : "pau-react-custom-focus",
          classes : "pauNot",
          icon    : pictos.fuentes.enlaces,
          value   : '',
          show    : shows.visual_estilo_focus.show,
          type    : "customBody",
          body    : <>
            <div style={{ width: '150px', display: 'inline-flex', flexWrap: 'wrap' }}>
              <span>Borde interno:</span><br/>
              <InputColor idSelector='pau-select-border-inner' initialValue={focusStyle.borderInner} callback={callbackChangeColorFocus( 'inner' )} /> <br />
              <span>Borde externo:</span><br/>
              <InputColor idSelector='pau-select-border-outer' initialValue={focusStyle.borderOuter} callback={callbackChangeColorFocus( 'outer' )} /> <br />
            </div>
          </>
        },
      ]
    };

  }

  sectionDiseno( settings, shows, pictos, t ) {
    return {
      header   : t.design.init,
      id       : "pauMenuDiseno",
      icon     : pictos.diseno.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          title  : t.design.increasePanel,
          id     : "pau_react_dis_aumento",
          classes: "pauNot",
          icon   : pictos.diseno.plantilla,
          show   : true,
          type   : "zoomPau",
        },
        /*{
          title  : t.design.selPAUDesignTemplate,
          id     : "pau_react_dis_plantilla",
          classes: "pauNot",
          icon   : pictos.diseno.plantilla,
          show   : true,
          type   : "proximamente"
        },
        {
          title  : t.design.selPositionPAU,
          id     : "pau_react_dis_position",
          classes: "pauNot",
          icon   : pictos.diseno.posicion,
          show   : true,
          type   : "proximamente"
        }*/
      ]
    };
  }

  sectionAyuda( settings, shows, pictos, t ) {
    return {
      header   : t.help.init,
      id       : "pauMenuAyuda",
      icon     : pictos.ayuda.principal,
      onClick  : eventClickPauActions,
      onKeyUp  : eventClickPauActions,
      showArrow: false,
      items    : [
        {
          title  : t.help.documentation,
          id     : "pau_react_ayuda_docum",
          classes: "pauNot",
          icon   : pictos.ayuda.documentacion,
          show   : true,
          type   : "link",
          link   : t.help.documentationLink,
        },
        /*{
          title  : t.help.videos + ` ${t.others.comingSoon}`,
          id     : "pau_react_ayuda_video",
          classes: "pauNot",
          icon   : pictos.ayuda.video,
          show   : true,
          type   : "link",
          link   : ""
        },*/
        // {
        //     title       : t.help.support,
        //     id          : "pau_react_ayuda_soporte",
        //     classes     : "pauNot",
        //     icon        : pictos.ayuda.soporte,
        //     show        : true,
        //     type        : "link"
        // },
        {
          title  : t.help.suggestions,
          id     : "pau_react_ayuda_sug",
          classes: "pauNot",
          icon   : pictos.ayuda.sugerencias,
          show   : true,
          type   : "link",
          link   : t.help.suggestionsLink,
        }
      ]
    };
  }

  setSections( settings ) {

    const { shows } = this.props;
    const pictos = pictosPanel();
    const t = reactTranslate;

    this.setState( update( this.state, {
      sections: { $set: [
          this.sectionPerfiles( settings, shows, pictos, t ),
          this.sectionGeneral( settings, shows, pictos, t ),
          this.sectionNavigation( settings, shows, pictos, t ),
          this.sectionFuentes( settings, shows, pictos, t ),
          this.sectionVisual( settings, shows, pictos, t ),
          this.sectionDiseno( settings, shows, pictos, t ),
          this.sectionAyuda( settings, shows, pictos, t )
        ] }
    }) )

  }



  validteShows( items ) {

    let shows = false;

    ItemInit: for ( let item of items ) {

      if ( item.hasOwnProperty( "group" ) ) {

        for ( let itemGroup of item.group ) {

          if ( itemGroup.show === "on" ) {
            shows = true;
            break ItemInit;
          }

        }

      } else {

        if ( item.show ) {
          shows = true;
          break;
        }

      }

    }

    return shows;

  }

  UNSAFE_componentWillMount() {
    this.setSections( this.props.settings );
  }

  zoomMasMenos( type ) {
    this.props.zoomMasMenos( type );
  }

  render() {

    const { sections } = this.state;
    // const sections = this.getSections( this.props.settings );
    // console.log( this.props.settings );

    return (
      sections ?
        <div className="pauAccessMenu">
          <nav id='pau-navigation'
               className='pau-navigation'
               aria-label='PAU'>
            <ul className="pauReactMenus" role="tablist">
              {sections.map( (section, index) => {

                let shows = this.validteShows( section.items );

                if ( shows ) {

                  return <li
                    key={section.id}>
                    <a href={`#pau-tabpanel-${section.id}`}
                       tabIndex={(index+1)*10}
                       onClick={section.onClick}
                       onKeyUp={section.onKeyUp}
                       role="tab"
                       aria-controls={`pau-tabpanel-${section.id}`}
                       aria-selected={false}
                       data-key="pauReactMenus">
                      <img alt={section.id}
                           className="iconEstInc"
                           src={section.icon}
                           data-key="pauReactMenus"/>
                      {/*<div className="pauSvgRender" style={{display: "none"}} pau-url-svg={section.icon}></div>*/}
                    </a>
                  </li>;

                }

                return null;

              } )}
            </ul>
          </nav>
          <div className="panelActions">
            {sections.map( (section, index) => {

              let shows = this.validteShows( section.items );

              if ( shows ) {

                return <div
                  key={section.id}
                  id={`pau-tabpanel-${section.id}`}
                  role="tabpanel">
                  <h2 className="titleSectionPanel">{section.header}</h2>
                  <CreateForm
                    index={(index+1)*10}
                    zoomMasMenos={this.zoomMasMenos.bind( this )}
                    header={section.header}
                    items={section.items}
                    settings={this.props.settings}
                  />
                </div>;

              }

              return null;

            } )}
          </div>
        </div> : ''
    );

  }

}

CreateSection.propTypes = {
  settings: PropTypes.object.isRequired,
  // sections    : PropTypes.array.isRequired
};

export default withCookies( CreateSection );
