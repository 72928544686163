import { extendsObj } from "../constants/extendsObj";
import { paulog } from "../constants/paulog";

const fetchSetup = (() => {

  let defaultOption = {
    headers: {
      'Authorization': `Bearer ${global.accessToken}`,
      'token'        : global.license,
    }
  };

  return async ( url, callback, options = {} ) => {

    let initOptions = extendsObj( defaultOption, options );

    return await fetch( url, initOptions )
      .then( resp => resp.json() )
      .then( ( data ) => {
        if ( data.status === 'error' ) {
          throw new Error( `Comunicación con la API. Tipo de error: [${data.error}]. Mensaje: ${data.message}` );
        }
        callback( data );
      } )
      .catch( ( error ) => paulog( error ) );

  }

})();

export default fetchSetup;