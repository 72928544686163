import { assignmentText } from "./assignmentText";
import { isEmptyNull } from "../../constants/isEmpty";

class PauAssignment {

  constructor( $pauSaveAssig ) {
    this.pauSaveAssig = $pauSaveAssig;
    this.error = this.msgInitError();
    this.xPath = '';
  }

  getXPath( element ) {

    this.xPath = '';
    let tagInicial = "";

    for ( let c = 0; element && element.nodeType === 1; element = element.parentNode ) {
      c++;
      let id      = element.getAttribute( "id" ),
          tagName = element.tagName.toLowerCase().trim();

      if ( tagName === "html" || tagName === "body" ) break;

      if ( c === 1 ) {
        tagInicial = tagName;
      }

      if (
        ! isEmptyNull( id ) &&
        (
          ( c === 1 && ( tagInicial === "img" || tagInicial === "i" ) ) &&
          tagInicial !== "img" || tagInicial !== "i"
        )
      ) {
        this.xPath = tagName + "#" + id + this.xPath;
        this.xPath = this.xPath.replaceAll( / {2,}/g, " " );
        break;
      } else {

        if ( element.parentNode.tagName !== "BODY" ) {
          this.xPath = " > " + tagName + this.xPath;
        } else {
          this.xPath = tagName + this.xPath;
        }

      }

    }

    return this.xPath.replaceAll( / {2,}/g, " " );

  }

  msgInitError() {

    return {
      c1 : {
        btnText : assignmentText.saveassignment,
        msgHelp : assignmentText.saveassignmenthelp,
      },
      c2 : {
        btnText : assignmentText.saveassignmenttext,
        msgHelp : assignmentText.saveassignmenttexthelp,
      }
    };

  }

}

export default PauAssignment;
