import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Switch } from 'antd';
// import 'antd/dist/antd.min.css';
import './../antd.css';
import PauHelpers from './../includes/PauHelpers';
import { pictosPanelHeader } from "../constants/pictosPanel";
import { reactTranslate } from "../constants/reactTranslate";
import { InputColor } from "../Components/InputColor";

class CreateForm extends Component {

  constructor( props ) {

    super( props );

    const { items, header, settings/*, globals*/ } = props;

    let zoom_pau = 100;

    if ( settings ) {
      zoom_pau = settings.zoom_pau.value;
    }

    this.state = {
      items,
      header,
      zoom_pau,
      settings
    };

  }

  zoomMasMenos( type ) {
    this.props.zoomMasMenos( type );
  }

  strToItems( items ) {

    const { index } = this.props;

    let map = [];

    items.forEach( (elem) => {

      if ( elem.hasOwnProperty( "group" ) ) {

        map = map.concat( elem.group.map( (item) => {

          let val = false;

          if ( item.key === item.value ) val = true;

          if ( item.show ) {

            return <div

                      className={`${item.key} ${item.classes}`}

                      key={item.key}>

                        <span className="pauItemTitle">{item.title}</span>
                        <span className="pauIconPanel">
                        <img src={item.icon}
                             alt={item.title}/>
                      </span>
                        <Switch
                            className={item.classes + ' itemPau'}
                            id={item.id}
                            data-id={item.id}
                            defaultChecked={val}
                            onChange={item.callback}
                            tabIndex={index}
                        />
                 </div>;



          }

          return false;

        } ) );

      } else {

        if ( elem.show ) {

          const key = PauHelpers.normalize( elem.title.replace( / /g, "" ).toLowerCase() );

          let link = null;
          let body = null;

          if ( elem.hasOwnProperty( "link" ) ) {
            link = elem.link;
          }

          if ( elem.hasOwnProperty( "body" ) ) {
            body = elem.body;
          }

          let elementType = this.elementsType( index, elem.type, elem.title, elem.id, elem.icon, elem.classes, key.replace( /-/g, "" ), elem.value, elem.callback, elem.min, elem.max, link, body );

          map.push( elementType );

        }

      }

    } );

    return map;

  };

  elementsType(
    index, type, title, id,
    icon, classes, key, value,
    callback, min = null,
    max = null, link = null,
    body = null
  ) {

    let elem,
        { globals } = this.props,
        { others }  = reactTranslate;

    switch ( type ) {

      case "switch" :

        elem =
          <div className={`${key} ${classes}`} key={key}>
              <span className="pauItemTitle">{title}</span>
              <span className="pauIconPanel">
                <img src={icon} alt={title}/>
              </span>
              <Switch
                  tabIndex={index}
                  className={classes + ' itemPau'}
                  id={id}
                  data-id={id}
                  defaultChecked={value}
                  onChange={callback}
              /> &nbsp;
          </div>;

        break;

      case "number" :

        elem =
          <div className={`${key} ${classes}`} key={`${key}-number`}>
            <span className="pauItemTitle">{title}</span>
              <span className="pauIconPanel">
              <img src={icon} alt={title}/></span>
              <InputNumber
                  className ='itemPau'
                  tabIndex={index}
                  min={min}
                  max={max}
                  id={id}
                  data-id={id}
                  style={{ marginLeft: 4 }}
                  defaultValue={value}
                  onChange={callback}
              />
          </div>;

        break;

      case "proximamente" :

        elem =
          <div className={`${key} ${classes}`}
               key={`${key}-number`}
          >
               <span className="pauItemTitle">
                 {title} <strong>({others.comingSoon})</strong>
               </span>
            <span className="pauIconPanel">
              <img src={icon} alt={title}/>
            </span>

          </div>;
        break;

      case "link" :

        let tagLink = "";

        if ( link ) {
          tagLink =
            <a className="itemPau"
                href={link}
               target="_blank"
               tabIndex={index}
            >
              {others.togo}
            </a>;
        }
        elem =
            <div className={`${key} ${classes}`}
                 key={`${key}-number`}
            >
                <span className="pauItemTitle">{title}</span>
                <span className="pauIconPanel">
              <img src={icon} alt={title}/>
            </span>
                {tagLink}
            </div>;

        break;

      case "zoomPau" :

        elem =
            <div className={`${key} ${classes}`} key={`${key}-number`}>
                <span className="pauItemTitle">{title}</span>
                <div id="pauPanelZoom">
              <span className="iconZoomInside">
                  <img onClick={this.zoomMasMenos.bind( this, "menos" )}
                       onKeyUp={(event) => {
                           if ( event.key === 'Enter' ) {
                               this.zoomMasMenos( "menos" );
                           }
                       }}
                       tabIndex={index}
                       src={`${pictosPanelHeader().menos}`}
                       alt="Botón para disminuir el tamaño del panel"/>
              </span>
                    <span id="pauPanelZoomPerc">
                  {this.state.zoom_pau}%
              </span>
                    <span className="iconZoomInside">
                  <img onClick={this.zoomMasMenos.bind( this, "mas" )}
                       onKeyUp={(event) => {
                           if ( event.key === 'Enter' ) {
                               this.zoomMasMenos( "mas" );
                           }
                       }}
                       tabIndex={index}
                       src={`${pictosPanelHeader().mas}`}
                       alt="Botón para aumentar el tamaño del panel"/>
              </span>
                </div>
            </div>;
        break;

      case "color" :

        elem =
          <div className={`${key} ${classes}`} key={key}>
            <span className="pauItemTitle">{title}</span>
            <span className="pauIconPanel">
              <img src={icon} alt={title}/>
            </span>
            <InputColor initialValue={value} callback={callback} /> &nbsp;
          </div>;

        break;

      case "customBody" :

        elem =
            <div className={`${key} ${classes}`} key={key}>
                <span className="pauItemTitle">{title}</span>
                <span className="pauIconPanel">
              <img src={icon} alt={title}/>
            </span>
                {body}
            </div>;

        break;

      default:
        break;

    }

    return elem;

  }

  render() {

    const { items } = this.state;

    return <React.Fragment>
      {this.strToItems( items )}
    </React.Fragment>;

  }

}

CreateForm.propTypes = {
  items: PropTypes.array.isRequired
};

export default CreateForm;
