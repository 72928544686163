export const inherited = (hotspot) => {
  if ( hotspot.position_mobile === 'inherited' ) {
    hotspot.position_mobile = hotspot.position;
  };

  if (hotspot.position_correction_mobile === 'inherited') {
    hotspot.position_correction_mobile = hotspot.position_correction;
  };

  if ( !hotspot.x_axis_position_mobile ) {
    hotspot.x_axis_position_mobile = hotspot.x_axis_position;
  };

  if ( !hotspot.y_axis_position_mobile ) {
    hotspot.y_axis_position_mobile = hotspot.y_axis_position;
  };

  if ( !hotspot.container_width_mobile ) {
    hotspot.container_width_mobile = hotspot.container_width;
  };

  if ( !hotspot.container_height_mobile  ) {
    hotspot.container_height_mobile = hotspot.container_height;
  };

  if( !hotspot.emergent_container_width_mobile ){
    hotspot.emergent_container_width_mobile = hotspot.emergent_container_width;
  }

  if( !hotspot.emergent_container_height_mobile ){
    hotspot.emergent_container_height_mobile = hotspot.emergent_container_height;
  }
  return hotspot;
}
