import ReactTestUtils from 'react-dom/test-utils';
import Cookies from 'js-cookie';

class ProfilesActions {

  static defecto() {

    return [

      // General
      {
        "pau_react_geral_todo": {
          "key" : "geral_todo",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_sonido": {
          "key" : "geral_sonido",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_geral_video": {
          "key" : "geral_video",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_lectura": {
          "key" : "geral_lectura",
          "type": "switch",
          "val" : true,
        }
      },

      // Navegación
      {
        "pau_react_nav_bigcursor": {
          "key" : "nav_bigcursor",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_nav_clic": {
          "key" : "nav_clic",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_nav_donde": {
          "key" : "nav_donde",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_zoom": {
          "key" : "nav_zoom",
          "type": "switch",
          "val" : false,
        }
      },

      // Fuentes
      {
        "pau_react_font_size": {
          "key" : "font_size",
          "type": "number",
          "val" : 1,
        }
      },

      {
        "pauFuentesLegible": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "readable",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pauFuentesDislexia": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "dyslexia",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_resaltar_links": {
          "key" : "font_resaltar_links",
          "type": "switch",
          "val" : true,
        }
      },

      // Visual
      {
        "pau_react_blanco_negro": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "blackwhite",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_invertir_colores": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "invert-colors",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau-react-resaltar-focus": {
          "key" : "visual_resaltar_focus",
          "type": "switch",
          "val" : true,
        }
      }

    ];

  }

  static visual() {

    return [

      // General
      {
        "pau_react_geral_todo": {
          "key" : "geral_todo",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_sonido": {
          "key" : "geral_sonido",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_video": {
          "key" : "geral_video",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_geral_lectura": {
          "key" : "geral_lectura",
          "type": "switch",
          "val" : false,
        }
      },

      // Navegación
      {
        "pau_react_nav_clic": {
          "key" : "nav_clic",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_donde": {
          "key" : "nav_donde",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_zoom": {
          "key" : "nav_zoom",
          "type": "switch",
          "val" : true,
        }
      },

      // Fuentes
      {
        "pau_react_font_size": {
          "key" : "font_size",
          "type": "number",
          "val" : 6,
        }
      },

      {
        "pauFuentesLegible": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "readable",
          "val"    : true,
          "unique" : true
        }
      },

      {
        "pauFuentesDislexia": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "dyslexia",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_resaltar_links": {
          "key" : "font_resaltar_links",
          "type": "switch",
          "val" : true,
        }
      },

      // Visual
      {
        "pau_react_blanco_negro": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "blackwhite",
          "val"    : true,
          "unique" : true
        }
      },

      {
        "pau_react_invertir_colores": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "invert-colors",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau-react-resaltar-focus": {
          "key" : "visual_resaltar_focus",
          "type": "switch",
          "val" : true,
        }
      }

    ];

  }

  static auditiva() {

    return [

      // General
      {
        "pau_react_geral_todo": {
          "key" : "geral_todo",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_sonido": {
          "key" : "geral_sonido",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_geral_video": {
          "key" : "geral_video",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_lectura": {
          "key" : "geral_lectura",
          "type": "switch",
          "val" : false,
        }
      },

      // Navegación
      {
        "pau_react_nav_clic": {
          "key" : "nav_clic",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_donde": {
          "key" : "nav_donde",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_zoom": {
          "key" : "nav_zoom",
          "type": "switch",
          "val" : true,
        }
      },

      // Fuentes
      {
        "pau_react_font_size": {
          "key" : "font_size",
          "type": "number",
          "val" : 4,
        }
      },

      {
        "pauFuentesLegible": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "readable",
          "val"    : true,
          "unique" : true
        }
      },

      {
        "pauFuentesDislexia": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "dyslexia",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_resaltar_links": {
          "key" : "font_resaltar_links",
          "type": "switch",
          "val" : true,
        }
      },

      // Visual
      {
        "pau_react_blanco_negro": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "blackwhite",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_invertir_colores": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "invert-colors",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau-react-resaltar-focus": {
          "key" : "visual_resaltar_focus",
          "type": "switch",
          "val" : true,
        }
      }

    ];

  }

  static cognitiva() {

    return [

      // General
      {
        "pau_react_geral_todo": {
          "key" : "geral_todo",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_geral_sonido": {
          "key" : "geral_sonido",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_geral_video": {
          "key" : "geral_video",
          "type": "switch",
          "val" : false,
        }
      },

      {
        "pau_react_geral_lectura": {
          "key" : "geral_lectura",
          "type": "switch",
          "val" : true,
        }
      },

      // Navegación
      {
        "pau_react_nav_clic": {
          "key" : "nav_clic",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_donde": {
          "key" : "nav_donde",
          "type": "switch",
          "val" : true,
        }
      },

      {
        "pau_react_nav_zoom": {
          "key" : "nav_zoom",
          "type": "switch",
          "val" : true,
        }
      },

      // Fuentes
      {
        "pau_react_font_size": {
          "key" : "font_size",
          "type": "number",
          "val" : 4,
        }
      },

      {
        "pauFuentesLegible": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "readable",
          "val"    : true,
          "unique" : true
        }
      },

      {
        "pauFuentesDislexia": {
          "key"    : "font_dislexia_legible",
          "type"   : "switch",
          "valText": "dyslexia",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_resaltar_links": {
          "key" : "font_resaltar_links",
          "type": "switch",
          "val" : true,
        }
      },

      // Visual
      {
        "pau_react_blanco_negro": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "blackwhite",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau_react_invertir_colores": {
          "key"    : "visual_colors",
          "type"   : "switch",
          "valText": "invert-colors",
          "val"    : false,
          "unique" : true
        }
      },

      {
        "pau-react-resaltar-focus": {
          "key" : "visual_resaltar_focus",
          "type": "switch",
          "val" : true,
        }
      }

    ];

  }

  static selection( profile ) {

    Cookies.remove( "noPersonalizado" );
    Cookies.set( "noPersonalizado", 1 );

    // if( profile === "personalizado" ) return;

    let profiles = {

      /**
       * Por defecto
       */
      "default": ProfilesActions.defecto(),

      /**
       * Diversidad Visual
       */
      "visual-diversity": ProfilesActions.visual(),

      /**
       * Diversidad Auditiva
       */
      "auditive-diversity": ProfilesActions.auditiva(),

      /**
       * Diversidad Cognitiva
       */
      "cognitive-diversity": ProfilesActions.cognitiva(),

      "custom": []

    };

    if ( profiles[ profile ].length ) {

      let actionReset = parseInt( Cookies.get( "actionReset" ), 10 );

      if ( !actionReset ) {

        profiles[ profile ].forEach( elem => {

          let settings = JSON.parse( Cookies.get( "settings" ) );

          let name                                             = Object.keys( elem )[ 0 ],
              $select                                          = document.querySelector( "#" + name ),
              $elem                                            = elem[ name ],
              { type, val, key, unique = false, valText = "" } = $elem,
              config                                           = settings[ key ];

          if ( $select ) {

            switch ( type ) {

              case "switch" :

                if ( unique ) {

                  if ( config.value === "" ) {

                    if ( val ) {
                      ReactTestUtils.Simulate.click( $select );
                    }

                  } else {

                    // Si no está vacío
                    if ( val ) {

                      if ( valText !== config.value ) {
                        ReactTestUtils.Simulate.click( $select );
                      }

                    } else {

                      if ( valText === config.value ) {
                        ReactTestUtils.Simulate.click( $select );
                      }

                    }

                  }

                  // Fin Unique

                } else {

                  if ( val !== config.value ) {
                    ReactTestUtils.Simulate.click( $select );
                  }

                }

                break;

              case "number" :

                $select.value = val;

                ReactTestUtils.Simulate.change( $select );
                ReactTestUtils.Simulate.focus( $select );

                break;

              default:
                break;

            }

          }

        } );

      }
    }

    // console.log( "\n" );
    // console.log( "Despues: " );
    // console.log( JSON.parse( Cookies.get( "settings" ) ).font_dislexia_legible );
    // console.log( "\n" );

    Cookies.remove( "noPersonalizado" );
    Cookies.set( "noPersonalizado", 0 );

  }

}

export default ProfilesActions;
