import React, { useEffect } from 'react';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import Assignment from "./includes/Assignment/Assignment";
import ReactDOM from "react-dom/client";
import { log } from "./constants/log";
import './includes/HelpersDOM';
import { logicSelectElement } from "./includes/Assignment/logicSelectElement";
import fetchX from "./includes/fetchSetup";
import ReactTestUtils from 'react-dom/test-utils';
import { getUrlHost } from "./constants/getUrlHost";
import {element} from "prop-types";

global.pauActiveElement = false;

document.addEventListener( "DOMContentLoaded", () => {
  let interactiveTags = `a,select,button,input,textarea`;
 /* let interactiveTags = [
      'a',
      'select',
      'button',
      'input',
      'textarea'
  ].join(',');*/

  /*Agrega el atributo tabindex = 1 a las etiquetas interactivas declaradas anteriormente
    con el fin de que el el panel de Pau sea el ùltimo en enfocarse cuando se utiliza la navegaciòn por tab
  * */
  document.querySelectorAll( interactiveTags ).forEach( element => {
    if( !element.matches(  'itemPau' ) ){
      element.tabIndex = 2;
    }
  });


   /*document.querySelector(' .skip-to-content ').addEventListener(' keypress ', function ( e ) {
    if ( e.key === 'Enter' ) {
      e.preventDefault();
      const element = document.querySelector(' .pauAccessMenu > nav > ul > li > a ')
      element.focus()
    }
  })*/


  document.addEventListener( 'keyup', ( e ) => {

    if ( e.key === 'Escape' && global.pauActiveElement ) {

      let $pauMainAccess = document.querySelector( "#pauMainAccess" ),
          $pauContMain   = document.querySelector( '#pauContMain' ),
          $iconPauAccess = document.querySelector( '.iconPauAccess' );

      $iconPauAccess.removeClass( 'pauPanelDesplegado' );

      $pauContMain.setAttribute( "data-value", '1' );

      $pauMainAccess
        .css( 'height', '80px' )
        .anime( {
          right: '-450px'
        } );

      $iconPauAccess.css( {
        "width"     : "80px",
        "height"    : "80px",
        "padding"   : "0",
        "background": "transparent"
      } );

      $iconPauAccess.innerHTML = global.pauIconsMain.ico_principal_pau;
      document.activeElement.blur();
      global.pauActiveElement = false;

    }

  });

  let urlParams = new URLSearchParams( location.search ),
      paumode   = urlParams.get( 'paumode' );

  if ( paumode === 'assignment' ) {

    let pauAssignment = document.createElement( 'div' ),
        projectId     = urlParams.get( 'project_id' ),
        hotspotId     = urlParams.get( 'hotspot_id' );

    pauAssignment.setAttribute( 'id', 'pauAssignment' );
    document.body.insertAdjacentElement( 'beforeend', pauAssignment );

    function AppWithCallbackAfterRender() {
      useEffect(() => {
        logicSelectElement( projectId, hotspotId );
      });

      return <Assignment projectId={projectId} hotspotId={hotspotId}/>;
    }

    ReactDOM.createRoot( pauAssignment ).render(
      <AppWithCallbackAfterRender />
    );

  }

  let pauMainAccess = document.createElement( 'div' );
  pauMainAccess.setAttribute( 'id', 'pauMainAccess' );
  document.body.append( pauMainAccess );

  global.urlApiBase = process.env.NODE_ENV !== 'production'
                        ? 'https://pau-cloud.test/api/v1/'
                        : 'https://cloudpau.com/api/v1/';

  if ( global.accessToken === undefined ) {
    log( 'Se necesita establecer el accessToken para la conexión con Cloud PAU' );
  } else if ( global.license === undefined ) {
    log( 'Se necesita establecer la licencia para la conexión con Cloud PAU' );
  } else {

    global.urlBase = getUrlHost();

    global.headers = {
      'Authorization': `Bearer ${global.accessToken}`,
      'token'        : global.license,
    };

    let urlPauActive       = `${urlApiBase}pauActive`,
        pauActive          = false,
        promisePauActivate = fetchX( urlPauActive, ( data ) => {
         pauActive = data.pauActive;
        } );

    promisePauActivate.then( _ => {

      if ( pauActive ) {

        ReactDOM.createRoot( pauMainAccess ).render(
          <CookiesProvider>
            <App/>
          </CookiesProvider>
        );

      }

    } );

  }

} );
