import urlBasePau from "./urlBasePau";

const pictosPanel = () => {

  return {

    perfiles: {
      principal    : `${urlBasePau}panel/perfiles.png`,
      porDefecto   : `${urlBasePau}panel/perfiles/por-defecto.png`,
      visual       : `${urlBasePau}panel/perfiles/visual.png`,
      auditivo     : `${urlBasePau}panel/perfiles/auditivo.png`,
      cognitiva    : `${urlBasePau}panel/perfiles/cognitiva.png`,
      tipo         : `${urlBasePau}panel/perfiles/tipo.png`,
      personalizado: `${urlBasePau}panel/perfiles/personalizado.png`
    },

    general: {
      principal: `${urlBasePau}panel/general.png`,
      todo     : `${urlBasePau}panel/general/todo.png`,
      sonido   : `${urlBasePau}panel/general/sonido.png`,
      video    : `${urlBasePau}panel/general/video.png`,
      lectura  : `${urlBasePau}panel/general/lectura.png`
    },

    navegacion: {
      principal: `${urlBasePau}panel/navegacion.png`,
      bigcursor: `${urlBasePau}panel/navegacion/bigcursor.png`,
      nav      : `${urlBasePau}panel/navegacion/navegacion.png`,
      donde    : `${urlBasePau}panel/navegacion/donde.png`,
      zoom     : `${urlBasePau}panel/navegacion/zoom.png`
    },

    fuentes: {
      principal: `${urlBasePau}panel/fuentes.png`,
      tamanio  : `${urlBasePau}panel/fuentes/tamanio-fuentes.png`,
      legible  : `${urlBasePau}panel/fuentes/legible.png`,
      dislexia : `${urlBasePau}panel/fuentes/dislexia.png`,
      enlaces  : `${urlBasePau}panel/fuentes/enlaces.png`
    },

    visual: {
      principal      : `${urlBasePau}panel/visuales.png`,
      blancoNegro    : `${urlBasePau}panel/visuales/blanco-negro.png`,
      invertirColores: `${urlBasePau}panel/visuales/invertir-colores.png`
    },

    diseno: {
      principal: `${urlBasePau}panel/diseno.png`,
      plantilla: `${urlBasePau}panel/diseno/plantillas.png`,
      posicion : `${urlBasePau}panel/diseno/posicion.png`
    },

    ayuda: {
      principal    : `${urlBasePau}panel/ayuda.png`,
      documentacion: `${urlBasePau}panel/ayuda/documentacion.png`,
      video        : `${urlBasePau}panel/ayuda/video.png`,
      soporte      : `${urlBasePau}panel/ayuda/soporte.png`,
      sugerencias  : `${urlBasePau}panel/ayuda/sugerencias.png`
    }

  }

}

const pictosPanelHeader = () => {

  return {
    icoPrin: `${urlBasePau}header/ico-principal-pau.svg`,
    icoSub : `${urlBasePau}header/ico-pau.svg`,
    mas    : `${urlBasePau}header/pau-boton-mas.png`,
    menos  : `${urlBasePau}header/pau-boton-menos.png`,
    reset  : `${urlBasePau}header/resetear.png`
  }

}

export { pictosPanel, pictosPanelHeader };
