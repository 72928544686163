import PauAssignment from "./PauAssignment";
import { getUrlHost } from "../../constants/getUrlHost";

export const logicSelectElement = ( projectId, hotspotId ) => {

  'use strict';

  let $pauSelectorView = document.querySelector( "#pau-selector-view h4#pauRute" ),
      $pauSaveAssig    = document.querySelector( "#pauSaveAssig" ),
      $pauDataPath     = document.querySelector( "#pauDataPath" );

  let xPath      = "",
      xPathFinal = "";

  let url = `admin/projects/${projectId}/?hotspot_id=${hotspotId}&element=`,
      $selectorAssig;
  url = process.env.NODE_ENV !== 'production'
    ? `https://pau-cloud.test/${url}`
    : `https://cloudpau.com/${url}`;

  let pauAssig = new PauAssignment( $pauSaveAssig );

  document.body.not( ".pauNoEventAssig, .pauNoEventAssig *" )
    .on( "mouseover", function ( e ) {

      if ( e.target.id.indexOf( 'selector' ) !== -1 || e.target.tagName === 'BODY' || e.target.tagName === 'HTML' || e.shiftKey ) return;

      let $target      = e.target,
          targetOffset = $target.getBoundingClientRect();

      // console.log( targetOffset );
      // console.log( "height: " + targetOffset.height );
      // console.log( "width: " + targetOffset.width );
      // console.log( "bottom: " + targetOffset.bottom );
      // console.log( "left: " + targetOffset.left );
      // console.log( "right: " + targetOffset.right );
      // console.log( "top: " + targetOffset.top );
      // console.log( "x: " + targetOffset.x );
      // console.log( "y: " + targetOffset.y );

      $target.not( ".pauNoEventAssig, .pauNoEventAssig *" ).addClass( 'pau-onlive' );

    } ).on( "mouseout", function ( e ) {

    if ( e.target.id.indexOf( 'selector' ) !== -1 || e.target.tagName === 'BODY' || e.target.tagName === 'HTML' ) return;

    let $target = e.target;

    $target.not( ".pauNoEventAssig, .pauNoEventAssig *" ).removeClass( 'pau-onlive' );

  } ).on( "click", function ( e ) {

    if ( e.target.id.indexOf( 'selector' ) !== -1 || e.target.tagName === 'BODY' || e.target.tagName === 'HTML' ) return;
    if ( e.target.id === 'draggable' ) return;


    let $target = e.target,
        id      = $target.getAttribute( "id" );

    if( e.shiftKey ) {

      e.preventDefault();

      const tagA = $target.closest( "a" );

      if ( $target.tagName === 'A' || tagA ) {

        $target = tagA || $target;
        let href = $target.getAttribute( "href" );

        if ( href ) {

          let urlHost = getUrlHost();
          let firstLetter = href.charAt( 0 );

          if ( firstLetter === "/" ) {
            href = href.substring( 1 );
          }

          if ( href.includes( urlHost ) ) {
            href = href.replace( urlHost, "" );
          }

          let urlGo;
          if ( href.includes( '#' ) ) {
            urlGo = urlHost + location.search + href;
          } else {
            href += href.includes( '?' ) ? '&' : '?';
            urlGo = urlHost + href + location.search.substring( 1 );
          }

          location.href = urlGo;

        }

      }

      return;

    }

    $selectorAssig = $target;

    if ( $target.matches( '.pauNoEventAssig, .pauNoEventAssig *' ) ) return;

    e.preventDefault();

    xPath = pauAssig.getXPath( $target ) || '';
    xPath = xPath.length > 0 ? xPath.trim() : xPath;

    $selectorAssig = $target;

    let { tagName } = e.target;

    /*if ( !/\#/.test( xPath ) ) {

     if ( tagName === 'IMG' ) {
     xPath = `${xPath}[src='${$target.getAttribute( 'src' )}']`;
     } else if ( tagName === 'A' ) {
     xPath = `${xPath}[class='${$target.getAttribute( 'class' ).replaceAll( / ?(pau-onlive|pau-resaltar)/g, '' )}'][href='${$target.getAttribute( 'href' )}']`;
     } else if ( tagName === 'I' ) {
     xPath = `${xPath}[class='${$target.getAttribute( 'class' )}']`;
     }

     }*/

    let xPathElements  = document.querySelectorAll( xPath ),
        targetElements = $target?.parentElement.children,
        indexElement   = '';

    if ( /*xPathElements.length > 1 || */targetElements.length > 1 && !$target.id ) {

      /*if ( xPathElements.length !== targetElements.length ) {

       let targetParent = $target.parentElement;

       while( targetParent.parentElement.children.length === 1 ) {
       targetParent = targetParent.parentElement;
       }

       let xPathParent = pauAssig.getXPath( targetParent ),
       indexParent = Array.from( targetParent.parentElement.children ).indexOf( targetParent ),
       xPathPost   = xPath.split( xPathParent )[1];

       if ( targetParent.parentElement.tagName === 'BODY' ) {
       xPathParent = `body > ${xPathParent}`;
       }

       xPath = `${xPathParent}:nth-child(${indexParent+1})${xPathPost}`;

       }*/

      // if ( $target?.parentElement.children.length > 1 ) {
      indexElement = Array.from( $target?.parentElement.children ).indexOf( $target ) + 1;
      indexElement = `:nth-child(${indexElement})`;
      // }

    }

    xPathFinal = xPath + indexElement;
    $pauSelectorView.innerHTML = xPathFinal;
    $pauDataPath.value = xPathFinal;

  } );

  $pauSaveAssig.on( "click", () => {
    location.href = url + xPathFinal.replace( "#", "PAUNUM" );
  } );

};
