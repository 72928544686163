export const reactTranslate = {
  "profiles"   : {
    "init"               : "Perfiles",
    "default"            : "Por defecto",
    "visualDiversity"    : "Diversidad Visual",
    "auditiveDiversity"  : "Diversidad Auditiva",
    "cognitiveDiversity" : "Diversidad Cognitiva",
    "custom"             : "Personalizar"
  },
  "general"    : {
    "init"                : "General",
    "showHideAll"         : "Mostrar / Esconder todo",
    "activateGeralSound"  : "Activar el Sonido General",
    "allLSEVideos"        : "Todos los Vídeos LSE (Lenguaje de señas española)",
    "readingSoundContent" : "Locución del contenido"
  },
  "navigation" : {
    "init"        : "Navegación",
    "largeCursor" : "Cursor grande",
    "clickSound"  : "Sonido al click",
    "showWhere"   : "Muestra dónde estás",
    "zoomLinks"   : "Zoom en enlaces"
  },
  "fonts"      : {
    "init"           : "Fuentes",
    "fontSize"       : "Tamaño de la fuente (1 - 9 em)",
    "readable"       : "Legible",
    "dyslexia"       : "Dislexia",
    "highlightLinks" : "Enlaces resaltados"
  },
  "visual": {
    "init"            : "Visual",
    "blackWhite"      : "Negro/Blanco",
    "invertColors"    : "Invertir los colores",
    "selectionFocus"  : "Destacar el Foco",
    "customStyleFocus": 'Personalizar color del foco'
  },
  "design"     : {
    "init"                 : "Diseño",
    "increasePanel"        : "Aumento del panel",
    "selPAUDesignTemplate" : "Seleccionar plantilla de diseño PAU",
    "selPositionPAU"       : "Seleccionar posición de PAU"
  },
  "help"       : {
    "init"              : "Ayuda",
    "documentation"     : "Documentación",
    "documentationLink" : "https:\/\/estudioinclusivo.com\/products\/panel-de-accesibilidad-universal\/documentation-plugin-pau\/?lang=es",
    "videos"            : "Videos",
    "support"           : "Support",
    "suggestions"       : "Sugerencias",
    "suggestionsLink"   : "https:\/\/estudioinclusivo.com\/contacto\/?lang=es"
  },
  "others"     : {
    "togo"       : "Ir a ver",
    "comingSoon" : "Próximamente"
  }
};
