import './styles.css';

const FocusPanelButton = () => {

    //agregar los eventos al botón
    const focusPauAccessElm = ( e ) => {
        e.preventDefault();
        if ( ( e.type === 'keypress' && e.key === 'Enter' ) || e.type === 'click' ) {
            document.querySelector('.pauAccessMenu > nav > ul > li > a').focus();
        }
    } 

    return (
        <button
            tabIndex={ 1 }
            className={ 'focusPanelButton' }
            onKeyPress={ focusPauAccessElm } 
            onClick={ focusPauAccessElm }
        >
            Saltar al panel PAU
        </button>
    )
}

export default FocusPanelButton
