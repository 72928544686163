import tippy from "tippy.js/headless";
export const createTippy = ( $elem , optionsTippy ) => {

  tippy( $elem, {
    ...optionsTippy,
    render( instance ) {
      // The recommended structure is to use the popper as an outer wrapper
      // element, with an inner `box` element
      const popper = document.createElement('div');
      const box = document.createElement('div');

      popper.appendChild( box );
      box.innerHTML = instance.props.content;

      function onUpdate(prevProps, nextProps) {
        // DOM diffing
        if (prevProps.content !== nextProps.content) {
          box.textContent = nextProps.content;
        }
      }
      // Return an object with two properties:
      // - `popper` (the root popper element)
      // - `onUpdate` callback whenever .setProps() or .setContent() is called
      return {
        popper,
      };
    },
  });
}

