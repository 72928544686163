import Cookies from 'js-cookie';
import PauHelpers from './PauHelpers';
import { isMobile } from 'react-device-detect';
import ReactTestUtils from 'react-dom/test-utils';
import { isEmpty } from "../constants/isEmpty";
import {log} from "../constants/log";

class AccesibilityActions {

  static sonidoClick;

  /**
   * Navegación
   */

  static donde( checked ) {

    let $pauDonde = document.querySelector( "#pauDonde" );

    if ( checked ) {
      $pauDonde?.show();
    } else {
      $pauDonde?.hide();
    }

  }

  static bigcursor( checked ) {
    document.body.classList.toggle( 'pau-bigcursor', checked );
  }

  static zoomLink( checked ) {

    if ( checked ) {

      let style = `
            <style id="pauZoomLink">
                .pauZoomLink:not(.pauNotZoomLink) {
                   text-decoration: none;
                   display: inline-block;
                   -webkit-transition: all 1s linear;
                   -moz-transition: all 1s linear;
                   -ms-transition: all 1s linear;
                   -o-transition: all 1.4s linear;
                   transition: all 1s linear;
                }

                .pauZoomLink:hover:not(.pauNotZoomLink) {
                   background: rgba(255, 255, 255, 0.8) !important;
                   color: rgba(0,0,0,1) !important;
                   box-shadow: 10px 10px 10px rgba(152, 152, 152, 0.93) !important;
                   -webkit-transform: scale(1.2,1.5);
                   -moz-transform: scale(1.2,1.5);
                   -ms-transform: scale(1.2,1.5);
                   -o-transform: scale(1.2,1.5);
                   transform: scale(1.2,1.5);
                }

                .pauZoomLink:hover:not(.pauNotZoomLink) * {
                   background: rgba(255, 255, 255, 0.8) !important;
                   color: rgba(0,0,0,1) !important;
                }
            </style>
            `;

      document.head.insertAdjacentHTML( 'beforeend', style );

    } else {
      document.querySelector( "#pauZoomLink" )?.remove();
    }

  }

  /*
   * Fuentes
   */
  static fuentes( type, checked ) {

    switch ( type ) {

      case "readable" :
        document.body.classList.toggle( 'pau-lecfacil', checked );
        break;

      case "dyslexia" :
        document.body.classList.toggle( 'pau_open_dislexia', checked );
        break;

      default:
        break;

    }

  }

  /*
   * Aumenta el tamaño de la fuente
   */
  static tamanioFuente( tamanio ) {

    let sizes = {
      1: 1,
      2: 1.25,
      3: 1.5,
      4: 1.75,
      5: 2,
      6: 2.25,
      7: 2.5,
      8: 2.75,
      9: 3
    };

    document.querySelector( 'html' ).css( 'cssText', `font-size: ${sizes[ tamanio ] * 16}px !important` );

  }

  /*
   * Resaltar enlaces
   */
  static resaltarEnlaces( checked ) {

    let enlaces = document.querySelectorAll( "a" );
    enlaces.toggleClass( "pau-resaltar", checked )
    enlaces.toggleClass( "pau-no-resaltar", !checked );

  }

  /*
   * Visual
   */
  static visual( type, checked ) {

    switch ( type ) {

      case "blackwhite" :
        document.querySelector( "html" ).classList.toggle( 'pau-greyscale', checked );
        break;

      case "invert-colors" :
        document.querySelector( "html" ).classList.toggle( 'pau-accessability-invert', checked );
        break;

      default:
        break;

    }

  }

  /**
   * Reset cookie focus
   */
  static resetCoockieFocus( cookies ) {

    console.log( "resetCoockieFocus" );

    cookies.remove( "focusStyle", { path: "/" } );
    let colorInner = '#000000';
    let colorOuter = '#ffffff';
    cookies.set( 'focusStyle', {
      borderInner: colorInner,
      borderOuter: colorOuter,
    }, { path: '/' } );

    AccesibilityActions.focus( true );

    let selectFocusInner = document.querySelector( "#pau-select-border-inner" ),
        selectFocusOuter = document.querySelector( "#pau-select-border-outer" );

    selectFocusInner.value = colorInner;
    selectFocusOuter.value = colorOuter;

    ReactTestUtils.Simulate.change( selectFocusInner );
    ReactTestUtils.Simulate.change( selectFocusOuter );

  }

  /*
   * Focus
   */
  static focus( checked ) {

    let focusInner = document.querySelector( "#pau-focus-style-inner" ),
        focusOuter = document.querySelector( "#pau-focus-style-outer" );

    focusInner?.remove();
    focusOuter?.remove();

    if ( checked ) {

      const focusStyle = JSON.parse( Cookies.get( "focusStyle" ) );

      let style = `
            <style id="pau-focus-style-inner">
                :focus {
                  outline: 5px solid ${focusStyle.borderInner} !important;
                }
            </style>
            <style id="pau-focus-style-outer">
                :focus {
                  box-shadow: 0 0 0 8px ${focusStyle.borderOuter} !important;
                }
            </style>
            `;

      document.head.insertAdjacentHTML( 'beforeend', style );

    }

  }

  /**
   * Zoom Pau
   */
  static zoomPanel( tamanio ) {

    let resize = Math.round(( devicePixelRatio ) * 100 );
    let relation = ( tamanio / resize ) * 100;

    if ( isMobile ) {
        relation+=40;
        if( matchMedia('( min-width:320px) and (max-width:425px) and (max-height:256px) ').matches ){
          relation-=40;
        }
    }

    let $pauMainAccess = document.getElementById( "pauMainAccess" );

    if ( PauHelpers.isFirefox() ) {

      tamanio = tamanio / 100;

      // transform: scale(1.1);
      // transform-origin: 100% 0;

      $pauMainAccess.style.transformOrigin = "100% 0";
      $pauMainAccess.style.transform = `scale( ${tamanio}, ${tamanio} )`;

    } else {

      $pauMainAccess.style.zoom = (relation) + "%";

    }

  }

  static getSettings( key = null ) {

    let settings = JSON.parse( Cookies.get( "settings" ) );

    if ( key ) {
      return settings[ key ];
    }

    return settings;

  }

  static callbackSonidoClick() {

    // let $pauClickCircle = document.querySelector( "#pauClickCircle" );

    let setti                                  = AccesibilityActions.getSettings(),
        { geral_todo, geral_sonido, nav_clic } = setti;

    if ( !geral_todo.value ) return;
    if ( !geral_sonido.value ) return;
    if ( !nav_clic.value ) return;

    /*$pauClickCircle.css( {
       display: "block",
       left   : e.clientX - 15 + 'px',
       top    : e.clientY - 15 + 'px'
     } );

     setTimeout( () => {
      $pauClickCircle.fadeOut( 200 );
     }, 200);*/

    AccesibilityActions.sonidoClick.currentTime = 0;
    AccesibilityActions.sonidoClick.play();

  }

  static initActions( { settings } ) {

    let profDef = Cookies.get( 'profileDefault' );

    if ( typeof profDef === "undefined" ) {

      Cookies.remove( "profileDefault", { path: "/" } );
      Cookies.set( "profileDefault", profDef, { path: "/" } );

    }

    /**
     * Navegación
     */
    let pathBigcursor  = `${global.urlBase}cloudpau/assets/pau/body/bigcursor.svg`,
        pathBigcursorH = `${global.urlBase}cloudpau/assets/pau/body/bigcursor-hover.svg`,
        styleBigCursor = `
            <style id="pauBigcursorStyle">
                .pau-bigcursor {
                    cursor: url( ${pathBigcursor} ), auto !important;
                }
                .pau-bigcursor a:hover,
                .pau-bigcursor button:hover {
                    cursor: url( ${pathBigcursorH} ), auto !important;
                }
            </style>
            `;

    document.head.insertAdjacentHTML( 'beforeend', styleBigCursor );

    // Cursor grande
    AccesibilityActions.bigcursor( settings.nav_bigcursor.value );

    // Donde
    AccesibilityActions.donde( settings.nav_donde.value );

    // Zoom
    document.querySelectorAll( 'a, button' ).addClass( 'pauZoomLink' );
    document.querySelectorAll( "li a" ).removeClass( "pauZoomLink" );

    AccesibilityActions.zoomLink( settings.nav_zoom.value );

    // document.body.insertAdjacentHTML( 'afterbegin', '<div id="pauClickCircle"></div>' );

    document.addEventListener( "click", AccesibilityActions.callbackSonidoClick );

    // Fuentes
    AccesibilityActions.fuentes( settings.font_dislexia_legible.value, true );
    AccesibilityActions.tamanioFuente( settings.font_size.value );
    AccesibilityActions.resaltarEnlaces( settings.font_resaltar_links.value );

    // Visual
    AccesibilityActions.visual( settings.visual_colors.value, true );
    AccesibilityActions.focus( settings.visual_resaltar_focus.value );

    global.zoom_pau = settings.zoom_pau.value;
    // Otros
    AccesibilityActions.zoomPanel( global.zoom_pau );

    window.addEventListener( 'resize', () => {
      AccesibilityActions.zoomPanel( global.zoom_pau );
    });

    let dzoom = document.getElementById( "pauDisplayZoom" );
    dzoom.childNodes[ 0 ].textContent = settings.zoom_pau.value;

  }

  static diseno( settings ) {

    let $pauDisenoAndCustom = document.querySelector( '#pauDisenoAndCustom' ),
        style = `:root {
                    --pauFondo: ${settings.diseno_pau_fondo.value};
                    --pauPrimario: ${settings.diseno_pau_primario.value};
                    --pauSecundario: ${settings.diseno_pau_secundario.value};
                }
                ${settings.diseno_custom_css.value}
                `;

    let $main;

    if ( $pauDisenoAndCustom ) {
      $pauDisenoAndCustom.empty();
      $main = $pauDisenoAndCustom;
    } else {
      style = `<style id="pauDisenoAndCustom">${style}</style>`;
      $main = document.head;
    }

    $main.insertAdjacentHTML( 'beforeend', style );

  }

  static applyReset( settings, cookies ) {

    /**
     * Agregando la nueva configuración
     */

    AccesibilityActions.diseno( settings );

    let perfilDefecto = document.querySelector( '#pauPerfilPorDefecto' );

    if ( perfilDefecto.ariaChecked !== 'true' ) {
      Cookies.remove( "actionReset" );
      Cookies.set( "actionReset", 1 );
      ReactTestUtils.Simulate.click( perfilDefecto );
      Cookies.remove( "actionReset" );
      Cookies.set( "actionReset", 0 );
    }

    let geral_todo    = document.querySelector( '#pau_react_geral_todo' ),
        geral_sonido  = document.querySelector( '#pau_react_geral_sonido' ),
        geral_video   = document.querySelector( '#pau_react_geral_video' ),
        geral_lectura = document.querySelector( '#pau_react_geral_lectura' );

    /**
     * Sección General
     */
    if (
      settings.geral_todo.value && geral_todo.ariaChecked !== 'true' ||
      !settings.geral_todo.value && geral_todo.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( geral_todo );
    }

    if (
      settings.geral_sonido.value && geral_sonido.ariaChecked !== 'true' ||
      !settings.geral_sonido.value && geral_sonido.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( geral_sonido );
    }

    if (
      settings.geral_video.value && geral_video.ariaChecked !== 'true' ||
      !settings.geral_video.value && geral_video.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( geral_video );
    }

    if (
      settings.geral_lectura.value && geral_lectura.ariaChecked !== 'true' ||
      !settings.geral_lectura.value && geral_lectura.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( geral_lectura );
    }

    /**
     * Sección Navegación
     */
    let nav_bigcursor = document.querySelector( '#pau_react_nav_bigcursor' ),
        nav_clic      = document.querySelector( '#pau_react_nav_clic' ),
        nav_donde     = document.querySelector( '#pau_react_nav_donde' ),
        nav_zoom      = document.querySelector( '#pau_react_nav_zoom' );

    if (
      settings.nav_bigcursor.value && nav_bigcursor.ariaChecked !== 'true' ||
      !settings.nav_bigcursor.value && nav_bigcursor.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( nav_bigcursor );
    }

    if (
      settings.nav_clic.value && nav_clic.ariaChecked !== 'true' ||
      !settings.nav_clic.value && nav_clic.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( nav_clic );
    }

    if (
      settings.nav_donde.value && nav_donde.ariaChecked !== 'true' ||
      !settings.nav_donde.value && nav_donde.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( nav_donde );
    }

    if (
      settings.nav_zoom.value && nav_zoom.ariaChecked !== 'true' ||
      !settings.nav_zoom.value && nav_zoom.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( nav_zoom );
    }

    /**
     * Sección Fuentes
     */
    let pauFontSize        = document.querySelector( '#pau_react_font_size' ),
        pauFuentesLegible  = document.querySelector( '#pauFuentesLegible' ),
        pauFuentesDislexia = document.querySelector( '#pauFuentesDislexia' ),
        resaltar_links     = document.querySelector( '#pau_react_resaltar_links' );

    if ( settings.font_size.value != pauFontSize.value ) {
      ReactTestUtils.Simulate.change( pauFontSize, { target: { value: settings.font_size.value } } );
    }

    if ( isEmpty( settings.font_dislexia_legible.value ) ) {

      if ( pauFuentesLegible.ariaChecked === 'true' ) {
        ReactTestUtils.Simulate.click( pauFuentesLegible );
      } else if ( pauFuentesDislexia.ariaChecked === 'true' ) {
        ReactTestUtils.Simulate.click( pauFuentesDislexia );
      }

    } else {

      if ( settings.font_dislexia_legible.value === 'readable' && pauFuentesLegible.ariaChecked !== 'true' ) {
        ReactTestUtils.Simulate.click( pauFuentesLegible );
      } else if ( settings.font_dislexia_legible.value === 'dyslexia' && pauFuentesDislexia.ariaChecked !== 'true' ) {
        ReactTestUtils.Simulate.click( pauFuentesDislexia );
      }

    }

    if (
      settings.font_resaltar_links.value && resaltar_links.ariaChecked !== 'true' ||
      !settings.font_resaltar_links.value && resaltar_links.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( resaltar_links );
    }

    /**
     * Sección Visual
     */
    let blanco_negro     = document.querySelector( '#pau_react_blanco_negro' ),
        invertir_colores = document.querySelector( '#pau_react_invertir_colores' ),
        resaltar_focus   = document.querySelector( '#pau-react-resaltar-focus' );

    if (
      isEmpty( settings.visual_colors.value ) && blanco_negro.ariaChecked === 'true' ||
      settings.visual_colors.value === 'blackwhite' && blanco_negro.ariaChecked !== 'true'
    ) {
      ReactTestUtils.Simulate.click( blanco_negro );
    }

    if (
      isEmpty( settings.visual_colors.value ) && invertir_colores.ariaChecked === 'true' ||
      settings.visual_colors.value === 'invert-colors' && invertir_colores.ariaChecked !== 'true'
    ) {
      ReactTestUtils.Simulate.click( invertir_colores );
    }

    if (
      settings.visual_resaltar_focus.value && resaltar_focus.ariaChecked !== 'true' ||
      !settings.visual_resaltar_focus.value && resaltar_focus.ariaChecked === 'true'
    ) {
      ReactTestUtils.Simulate.click( resaltar_focus );
    }

    AccesibilityActions.resetCoockieFocus( cookies );

    console.log( 'Restore default finish' );

  }

}

// const accesMangAct = new AccesibilityActions();

export default AccesibilityActions;
